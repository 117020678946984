import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

let originPush = VueRouter.prototype.push
let originReplace = Vue.prototype.originReplace

VueRouter.prototype.push = function (location,res,rej) {
    if (res && rej ) {
        originPush.call(this,location,res,rej)
    } else {
        originPush.call(this,location,()=>{},()=>{})
    }
}

VueRouter.prototype.replace = function(location ,res,rej) {
    if (res && rej) {
        originReplace.call(this,location,res,rej)
    } else {
        originReplace.call(this,location,() => {},() => {})
    }
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/newslist',
    name: 'newslist',
    component: () => import(/* webpackChunkName: "newslist" */ '../views/NewsListView.vue')
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: () => import(/* webpackChunkName: "newsdetail" */ '../views/NewsDetailView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue')
  },
  {
    path: '/examdetail',
    name: 'examdetail',
    component: () => import(/* webpackChunkName: "examdetail" */ '../views/ExamDetailView.vue')
  },
  {
    path: '/exam',
    name: 'exam',
    component: () => import(/* webpackChunkName: "exam" */ '../views/ExamView.vue')
  },
  {
    path: '/classnobuy',
    name: 'classnobuy',
    component: () => import(/* webpackChunkName: "classnobuy" */ '../views/ClassNoBuy.vue')
  },
  {
    path: '/classdetail',
    name: 'classdetail',
    component: () => import(/* webpackChunkName: "classdetail" */ '../views/ClassDetail.vue')
  },
  {
    path: '/class',
    name: 'class',
    component: () => import(/* webpackChunkName: "class" */ '../views/ClassView.vue')
  },
  {
    path: '/study',
    name: 'study',
    component: () => import(/* webpackChunkName: "study" */ '../views/StudyView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
