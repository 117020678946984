<template>
  <div class="footer">
    <p>Copyright©2017-2023,edu.yuncaigo.com. All rights reserved.   |   ICP证：京ICP备19016327号    | 京ICP备19045306号-1    北京云和未来科技有限公司提供技术支持</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer {
  background: #f5f6f7;
  p {
    line-height: 90px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #979797;
  }
}
</style>