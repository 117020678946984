<template>
  <div class="header">
    <div class="header-open">
      <div class="header-open-ctx">
        <p>欢迎登陆人社部双碳职业教育培训平台</p>
        <div class="header-open-list">
          <div class="header-open-item" v-for="item in openList" :key="item.name" @click="goOther(item.src)">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="header-nav">
      <div class="header-nav-ctx">
        <div class="header-nav-logo">
          <img src="../assets/index/header-logo.png" alt="" @click="goPage('/')">
        </div>
        <div class="header-nav-container">
          <div class="header-nav-list">
            <div class="header-nav-item" v-for="item in navList" :key="item.name" :class="{'active':item.active}" @click="goPage(item.url[0])">{{ item.name }}</div>
          </div>
          <!-- <div class="header-nav-auth">
            <div class="header-nav-login">登录</div>
            <div class="header-nav-sign">注册</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openList: [
        { name: "学员" ,src: 'http://edu.yuncaigo.com/student'},
        { name: "教师/培训机构",src: 'https://www.kaoshixing.com/login/account/login/417534' },
        { name: "管理机构" ,src: 'http://edu.yuncaigo.com/admin'},
        { name: "招聘企业" },
      ],
      navList: [
        { name: "首页",url:['/'], active: true },
        { name: "政策要闻",url:['/news','/newslist','/newsdetail'], active: false },
        { name: "证书介绍",url:['/exam','/examdetail'], active: false },
        { name: "课程学习", url: ['/class', '/classnobuy','/classdetail','/study'], active: false },
        { name: "人才服务",url:[], active: false },
        { name: "培训基地",url:[], active: false },
        { name: "数据共享",url:[], active: false },
        { name: "平台介绍",url:[], active: false },
      ],
    };
  },
  watch: {
    $route(){
      this.changeMenu()
    }
  },
  mounted() {
    this.changeMenu()
  },
  methods: {
    goOther(url) {
      if (url) {
        window.open(url)
      }
    },
    changeMenu() {
      const p = this.$route.path
      this.navList.forEach((v,k) => {
        this.navList[k].active = false;
        if (v.url.includes(p)) this.navList[k].active = true
     })
    },
    goPage(url) {
      if (url !== this.$route.path) {
        this.$router.push(url)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  &-open {
    background: #ffffff;
    &-ctx {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
    }
    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
    }
    &-list {
      display: flex;
      align-items: center;
    }
    &-item {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #646a73;
      line-height: 40px;
      cursor: pointer;
      margin-left: 32px;
    }
  }
  &-nav {
    height: 64px;
    background: #3370ff;
    &-ctx {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-logo {
      img {
        display: block;
        height: 44px;
        width: auto;
      }
    }
    &-container {
      display: flex;
    }
    &-list {
      display: flex;
    }
    &-item {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 64px;
      margin-left: 20px;
      position: relative;
      cursor: pointer;
      &.active {
        font-weight: bold;
        &::after {
          content: "";
          height: 2px;
          background: #fff;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
    &-auth {
      display: flex;
      align-items: center;
      margin-left: 40px;
    }
    &-login {
      padding: 6px 12px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      letter-spacing: 1px;
      background: #ff6f0a;
      border-radius: 1px;
      cursor: pointer;
    }
    &-sign {
      padding: 5px 12px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      letter-spacing: 1px;
      background: #3370ff;
      border-radius: 1px;
      border: 1px solid #ffffff;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>