<template>
  <div id="app">
    <header-vue/>
    <router-view/>
    <footer-vue/> 
  </div>
</template>

<script>
import HeaderVue from './components/Header.vue';
import FooterVue from './components/Footer.vue';
export default {
  components: {
    HeaderVue,
    FooterVue
  }
}
</script>

<style>
html,body {margin: 0;padding: 0;min-width:1200px;}
p {margin: 0;}
</style>
