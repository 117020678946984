<template>
  <div class="index">
    <div class="index-banner">
      <div class="index-banner-img">
        <!-- <img src="../assets/index/banner.png" alt="" class=""> -->
        <div class="swiper-container index-banner-swipper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="index-banner-item">
                <img src="../assets/index/banner1.png">
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index-banner-item">
                <img src="../assets/index/banner4.png">
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index-banner-item">
                <img src="../assets/index/banner2.png">
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index-banner-item">
                <img src="../assets/index/banner3.png">
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index-banner-item">
                <img src="../assets/index/banner5.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-search">
        <div class="index-search-ctx">
          <h2>证书查询</h2>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="5em" class="index-search-ruleForm" :hide-required-asterisk="true">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name" size="small" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="证件号" prop="cardCode">
              <el-input v-model="ruleForm.cardCode" size="small" placeholder="请输入证件号"></el-input>
            </el-form-item>
            <el-form-item label="证书编号" prop="cardId">
              <el-input v-model="ruleForm.cardId" size="small" placeholder="请输入证书编号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <div class="index-search-codeBox">
                <el-input v-model="ruleForm.code" size="small" placeholder="请输入验证码"></el-input>
                <img src="../assets/index/code.png" alt="" class="index-search-codeImg">
              </div>
            </el-form-item>
            <el-button class="index-search-submit" @click="submitForm('ruleForm')">立即查询</el-button>
          </el-form>
        </div>
      </div>
    </div>
    <div class="index-certificate">
      <div class="index-certificate-title">
        <h2>热门证书</h2>
        <div class="index-certificate-more" @click="goCerd"><span>更多证书</span><img src="../assets/index/more.png" alt=""></div>
      </div>
      <div class="index-certificate-list">
        <div class="index-certificate-item" v-for="(item,index) in certificate" :key="item" @click="goCerdDetail(index)">
          <img src="../assets/index/certificate.png" alt="">
          <p>{{ item }}</p>
        </div>
      </div>
    </div>
    <div class="index-class">
      <div class="index-class-container">
        <div class="index-class-title">
          <h2>推荐课程</h2>
          <div class="index-class-more" @click="goClass"><span>更多课程</span><img src="../assets/index/more.png" alt=""></div>
        </div>
        <div class="index-class-list">
          <div class="index-class-item" @click="goClassDetail">
            <div class="top">
              <img src="../assets/class/cover9.png" alt="">
              <h5>零碳建筑和园区建设政策、法规</h5>
              <span>了解绿色建筑和园区的评价标准</span>
            </div>
            <div class="bottom">
              <i>园区</i>
              <span>李丛笑</span>
            </div>
          </div>
          <div class="index-class-item">
            <div class="top">
              <img src="../assets/class/cover6.png" alt="">
              <h5>建筑施工阶段的碳减排及减排路径</h5>
              <span>设计原则和方法</span>
            </div>
            <div class="bottom">
              <i class="or">建筑</i>
              <span>闫世刚</span>
            </div>
          </div>
          <div class="index-class-item">
            <div class="top">
              <img src="../assets/class/cover8.png" alt="">
              <h5>绿色施工技术应用</h5>
              <span>可再生能源利用</span>
            </div>
            <div class="bottom">
              <i class="ye">施工</i>
              <span>陈蕾</span>
            </div>
          </div>
          <div class="index-class-item">
            <div class="top">
              <img src="../assets/class/cover7.png" alt="">
              <h5>碳市场与碳金融</h5>
              <span>了解碳市场的基本原理</span>
            </div>
            <div class="bottom">
              <i class="bl">金融</i>
              <span>杨扬</span>
            </div>
          </div>
          <div class="index-class-item">
            <div class="top">
              <img src="../assets/class/cover3.png" alt="">
              <h5>零碳园区/建筑碳足迹管理</h5>
              <span>节能减排方向的知识</span>
            </div>
            <div class="bottom">
              <i>园区</i>
              <span>李涛</span>
            </div>
          </div>
          <div class="index-class-item">
            <div class="top">
              <img src="../assets/class/cover4.png" alt="">
              <h5>零碳建筑和园区规划</h5>
              <span>节能减排方向的知识</span>
            </div>
            <div class="bottom">
              <i>园区</i>
              <span>陈波</span>
            </div>
          </div>
          <div class="index-class-item">
            <div class="top">
              <img src="../assets/class/cover2.png" alt="">
              <h5>近零能耗园区/建筑设计和改造</h5>
              <span>节能设计和改造</span>
            </div>
            <div class="bottom">
              <i>园区</i>
              <span>张涵曦悦</span>
            </div>
          </div>
          <div class="index-class-item">
            <div class="top">
              <img src="../assets/class/cover5.png" alt="">
              <h5>全球零碳设计案例分析</h5>
              <span>分享实战经验</span>
            </div>
            <div class="bottom">
              <i>园区</i>
              <span>崔玥珺</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-teacher">
      <h2>师资队伍</h2>
      <div class="index-teacher-swiper">
        <section class="pc-banner">
          <div class="swiper-container index-teacher-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in teacherList" :key="index">
                <div class="index-teacher-item">
                  <img :src="item.imagePath" alt="" width="160" height="194">
                  <h5>{{ item.realName }}</h5>
                  <p>{{ item.positionName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-prev index-teacher-prev"></div>
          <div class="swiper-button-next index-teacher-next"></div>
        </section>
      </div>
    </div>
    <div class="index-cooperate">
      <div class="index-cooperate-container">
        <div class="index-cooperate-title">
          <h2>合作共建</h2>
          <div class="index-cooperate-more">
            <span>更多合作</span>
            <img src="../assets/index/more.png" alt="">
          </div>
        </div>
        <div class="index-cooperate-ctx">
          <div class="index-cooperate-list">
            <div class="index-cooperate-item">
              <img src="../assets/index/cooperact-num1.png" alt="">
              <span>太行乡村振兴人才学院</span>
            </div>
            <div class="index-cooperate-item">
              <img src="../assets/index/cooperact-num2.png" alt="">
              <span>北京市中广职业技能培训学校</span>
            </div>
            <div class="index-cooperate-item">
              <img src="../assets/index/cooperact-num3.png" alt="">
              <span>张家港市永联干部学院</span>
            </div>
            <div class="index-cooperate-item">
              <img src="../assets/index/cooperact-num4.png" alt="">
              <span>四川宝山村庄发展学院</span>
            </div>
            <div class="index-cooperate-item">
              <img src="../assets/index/cooperact-num5.png" alt="">
              <span>四川战旗乡村振兴培训学院</span>
            </div>
          </div>
          <div class="index-cooperate-image">
            <img src="../assets/index/cooperact-banner.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="index-case">
      <div class="index-cooperate-title">
        <h2>合作案例</h2>
        <div class="index-cooperate-more" @click="goBoss">
          <span>更多成功合作案例</span>
          <img src="../assets/index/more.png" alt="">
        </div>
      </div>
      <div class="index-case-list">
        <div class="swiper-container index-case-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="index-case-item">
                <img src="../assets/index/case1.png" alt="">
                <h5>中国电建集团江西省电力建设有限公司</h5>
                <p>我们使用拥有双碳证书的员工入职，他们对政策的把握和工作实践方面都表现的非常出色，这是政府资源一站式的服务平台，强烈建议企业人力资源人士充分使用该平台，找到合适的人才。</p><br>
                <div class="index-case-hover">
                  <div class="index-case-hidden"><span>·企业管理岗位培训</span><span>·企业员工技能提升</span></div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index-case-item">
                <img src="../assets/index/case3.png" alt="">
                <h5>山西科城环保产业协同创新研究院</h5>
                <p>双碳培训平台帮助企业管理实习期的员工，平台对这些员工从培训考试的过程管理延申到员工的绩效考核管理都有一套非常科学的评价体系，给我们对实习期的员工是否转正有了非常完善的评判标准。</p>
                <div class="index-case-hover">
                  <div class="index-case-hidden">
                    <span>·企业员工技能提升</span><span>·企业人员招聘发布</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index-case-item">
                <img src="../assets/index/case2.png" alt="">
                <h5>上海长宁人力资源有限公司</h5>
                <p>双碳培训平台为我单位每年提供多次人员业务提升培训，公司员工业务的专业化问题得到了有效提升。平台从培训-实习-绩效考核-正式用工-员工的培训提升等都提供了强大技术援助，确保企业、学员、员工取得事业发展的成功。</p>
                <div class="index-case-hover">
                  <div class="index-case-hidden"><span>·企业人员招聘发布</span><span>·企业管理岗位培训</span></div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index-case-item">
                <img src="../assets/index/case4.png" alt="">
                <h5>河北省众联能源环保科技有限公司</h5>
                <p>我们使用拥有双碳证书的员工入职，他们对政策的把握和工作实践方面都表现的非常出色，这是政府资源一站式的服务平台，强烈建议企业人力资源人士充分使用该平台，找到合适的人才。</p>
                <div class="index-case-hover">
                  <div class="index-case-hidden">
                    <span>·企业管理岗位培训</span><span>·企业员工技能提升</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="index-case-item">
                <img src="../assets/index/case5.png" alt="">
                <h5>中国联合网络通信有限公司</h5>
                <p>双碳培训平台帮助企业管理实习期的员工，平台对这些员工从培训考试的过程管理延申到员工的绩效考核管理都有一套非常科学的评价体系，给我们对实习期的员工是否转正有了非常完善的评判标准。</p>
                <div class="index-case-hover">
                  <div class="index-case-hidden">
                    <span>·企业员工技能提升</span><span>·企业人员招聘发布</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="swiper-button-prev index-case-prev"></div>
        <div class="swiper-button-next index-case-next"></div>
      </div>
    </div>
    <div class="index-friend">
      <div class="index-friend-item">
        <img src="../assets/index/friendbg1.png" alt="">
        <div class="index-friend-ctx">
          <h3>教学资源合作</h3>
          <span>如果您有课程资源，由此进行申请成为教学合作机构</span>
          <div class="index-friend-btn">立即入驻</div>
        </div>
      </div>
      <div class="index-friend-item">
        <img src="../assets/index/friendbg2.png" alt="">
        <div class="index-friend-ctx">
          <h3>招聘企业合作</h3>
          <span>如果您有招聘的需求，由此进行申请成为招聘企业合作机构</span>
          <div class="index-friend-btn">立即入驻</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        cardCode: "",
        cardId: "",
        code: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        cardCode: [
          { required: true, message: "请输入证件号", trigger: "blur" },
        ],
        cardId: [
          { required: true, message: "请输入证书编号", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      teacherList: [],
      certificate: [
        "零碳建筑和园区规划设计",
        "零碳建筑和园区施工项目管理",
        "零碳建筑和园区运维管理",
        "建筑和园区节能监测核查",
        "零碳建筑/园区评估咨询",
        "碳减排管理",
        "碳减排监测核查",
        "碳减排评估咨询",
        // "碳汇管理",
        // "碳汇监测核查",
      ],
    };
  },
  mounted() {
    this.getTeacherList();
    this.Lunbo1();
    this.bannerLun();
  },
  methods: {
    bannerLun() {
      this.$nextTick(() => {
        var swiper = new Swiper(".index-banner-swipper", {
          effect: "fade",
          loop: true,
          speed: 2000,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            stopOnLastSlide: false,
          },
        });
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getTeacherList() {
      const res = await this.$getAPI.getTeacherList();
      this.teacherList = res;
      this.Lunbo();
    },
    Lunbo() {
      this.$nextTick(() => {
        var swiper = new Swiper(".index-teacher-container", {
          slidesPerView: 6,
          spaceBetween: 24,
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 2000,
            disableOnInteraction: false,
            stopOnLastSlide: false,
          },
          // autoplayDisableOnInteraction: false,
          // disableOnInteraction: true,
          // observer: true,
          // observeParents: true,
          // paginationClickable: true,
          // mousewheelControl: true,
          // debugger: true,
          navigation: {
            nextEl: ".index-teacher-next",
            prevEl: ".index-teacher-prev",
            // hideOnClick: true,
          },
        });
      });
    },
    Lunbo1() {
      this.$nextTick(() => {
        var swiper = new Swiper(".index-case-container", {
          slidesPerView: 3,
          spaceBetween: 24,
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 2000,
            disableOnInteraction: false,
            stopOnLastSlide: false,
          },
          navigation: {
            nextEl: ".index-case-next",
            prevEl: ".index-case-prev",
          },
        });
      });
    },
    goCerd() {
      this.$router.push("/exam");
    },
    goCerdDetail(index) {
      console.log(index);
      if (index === 0) {
        this.$router.push("/examdetail");
      }
    },
    goClass() {
      this.$router.push("/class");
    },
    goClassDetail() {
      this.$router.push("/classnobuy");
    },
    goBoss() {
      window.open("http://job.mohrss.gov.cn/");
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  padding-top: 100px;
  &-banner {
    position: relative;
    &-item {
      text-align: center;
      height: 420px;
      position: relative;
      img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 420px;
        width: auto;
      }
    }
    &-img {
      width: 100%;
      height: 420px;
      overflow: hidden;
    }
  }
  &-search {
    position: absolute;
    top: 58px;
    left: 50%;
    width: 1200px;
    transform: translateX(-50%);
    z-index: 1;
    &-ctx {
      position: relative;
      width: 296px;
      height: 305px;
      background: linear-gradient(
        317deg,
        rgba(255, 255, 255, 0.94) 0%,
        #f9f9fa 100%,
        rgba(255, 255, 255, 0.86) 100%
      );
      border-radius: 4px;
      padding: 20px 24px;
      box-sizing: border-box;
    }
    h2 {
      margin: 0;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff6f0a;
      line-height: 25px;
    }
    &-ruleForm {
      margin-top: 24px;
      .el-form-item {
        margin-bottom: 14px;
      }
      :deep(.el-form-item__label) {
        padding-right: 5px;
        line-height: 30px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #515151;
        text-align: justify;
        &::after {
          content: "";
          width: 100%;
          display: inline-block;
        }
      }
      :deep(.el-input--small .el-input__inner) {
        height: 30px;
        line-height: 30px;
      }
      :deep(.el-form-item__content) {
        height: 30px;
        line-height: 30px;
      }
    }
    &-codeBox {
      display: flex;
      align-items: center;
    }
    &-codeImg {
      width: 53px;
      height: 30px;
    }
    &-submit {
      position: absolute;
      right: 24px;
      bottom: 20px;
      width: 96px;
      height: 30px;
      background: #ff6f0a;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 0;
      border: 0;
    }
  }
  &-certificate {
    width: 1200px;
    margin: 80px auto 50px;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        margin: 0;
      }
    }
    &-more {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #646a73;
        line-height: 22px;
        margin-right: 4px;
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
    &-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 26px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      background: #f3f6ff;
      border-radius: 4px;
      text-align: center;
      padding: 2px;
      margin-bottom: 30px;
      img {
        width: 284px;
        height: auto;
      }
      p {
        width: 284px;
        padding: 24px 0 0;
        margin-top: -4px;
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #646a73;
        line-height: 20px;
        text-align: center;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
  &-class {
    padding: 80px 0 50px;
    background: #fafafa;
    &-container {
      width: 1200px;
      margin: 0 auto;
    }
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        margin: 0;
      }
    }
    &-more {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #646a73;
        line-height: 22px;
        margin-right: 4px;
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
    &-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 26px;
    }
    &-item {
      width: 252px;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 0 14px 20px;
      .top {
        img {
          width: 100%;
          height: auto;
        }
        h5 {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 22px;
          margin: 14px 0 0;
          cursor: pointer;
        }
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          margin-top: 6px;
        }
      }
      .bottom {
        margin-top: 26px;
        display: flex;
        justify-content: space-between;
        i {
          font-style: normal;
          background: #00d6b9;
          border-radius: 4px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
          padding: 0 4px;
          &.or {
            background: #ff6f0a;
          }
          &.ye {
            background: #ffc60a;
          }
          &.bl {
            background: #14c0ff;
          }
        }
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #646a73;
          line-height: 17px;
        }
      }
      &:hover {
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.06);
      }
    }
  }
  &-teacher {
    width: 1200px;
    margin: 0 auto;
    height: 448px;
    position: relative;
    h2 {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 40px;
    }
    &-swiper {
      margin-top: 36px;
    }
    &-item {
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      padding: 10px 10px 12px;
      cursor: pointer;
      h5 {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
        margin: 0;
        margin-top: 10px;
        white-space: nowrap;
      }
      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #646a73;
        line-height: 20px;
        margin-top: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-prev {
      width: 34px;
      height: 34px;
      background: #ffffff;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.08);
      cursor: pointer;
      border-radius: 50%;
      text-align: center;
      line-height: 34px;
      color: #979797;
      position: absolute;
      left: -17px;
      top: 200px;
      z-index: 9;
      &:after {
        font-size: 12px;
      }
    }
    &-next {
      width: 34px;
      height: 34px;
      background: #ffffff;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.08);
      cursor: pointer;
      border-radius: 50%;
      text-align: center;
      line-height: 34px;
      color: #979797;
      position: absolute;
      right: -17px;
      top: 200px;
      z-index: 9;
      font-size: 12px;
      &:after {
        font-size: 12px;
      }
    }
  }
  &-cooperate {
    height: 456px;
    background: url(../assets/index/cooperact-bg.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    &-container {
      width: 1200px;
      margin: 0 auto;
    }
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        margin: 0;
      }
    }
    &-more {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #646a73;
        line-height: 22px;
        margin-right: 4px;
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
    &-ctx {
      display: flex;
      margin-top: 36px;
    }
    &-list {
      width: 346px;
      height: 380px;
      background: linear-gradient(185deg, #cddcff 0%, #fff 20%);
      border-radius: 4px;
      border-top: 1px solid #cddcff;
      border-bottom: 1px solid #e8f0ff;
      padding: 0 22px;
    }
    &-item {
      height: 72px;
      line-height: 72px;
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      img {
        width: 28px;
        height: 25px;
        margin-right: 20px;
      }
      span {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
      }
      &:last-child {
        border: 0;
      }
    }
    &-image {
      flex: 1;
      margin-left: 22px;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      img {
        width: 100%;
        position: absolute;
      }
    }
  }
  &-case {
    width: 1200px;
    margin: 80px auto 0;
    position: relative;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        margin: 0;
      }
    }
    &-more {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #646a73;
        line-height: 22px;
        margin-right: 4px;
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
    &-list {
      margin-top: 36px;
    }
    &-item {
      height: 320px;
      background: #f3f6ff;
      border-radius: 4px;
      padding: 24px 24px 0;
      margin-right: 20px;
      position: relative;
      img {
        width: 80px;
        height: 80px;
        display: block;
        margin: 0 auto;
      }
      h5 {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #133c9a;
        line-height: 25px;
        text-align: center;
      }
      p {
        text-indent: 2em;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #646a73;
        line-height: 20px;
      }
      &:hover {
        .index-case-hover {
          display: block;
        }
      }
    }
    &-prev {
      width: 34px;
      height: 34px;
      background: #ffffff;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.08);
      cursor: pointer;
      border-radius: 50%;
      text-align: center;
      line-height: 34px;
      color: #979797;
      position: absolute;
      left: -17px;
      top: 200px;
      z-index: 9;
      &:after {
        font-size: 12px;
      }
    }
    &-next {
      width: 34px;
      height: 34px;
      background: #ffffff;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.08);
      cursor: pointer;
      border-radius: 50%;
      text-align: center;
      line-height: 34px;
      color: #979797;
      position: absolute;
      right: 0;
      top: 200px;
      z-index: 9;
      font-size: 12px;
      &:after {
        font-size: 12px;
      }
    }
    &-hover {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #f3f6ff;
      cursor: pointer;
    }
    &-hidden {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      transform: translateY(-50%);
      span {
        display: block;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3370ff;
        letter-spacing: 4px;
      }
    }
  }
  &-friend {
    width: 1200px;
    margin: 80px auto;
    &-item {
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      h3 {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 45px;
        margin: 0;
      }
      span {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #eeeeff;
        line-height: 25px;
        margin-top: 14px;
      }
      &:first-child {
        margin-bottom: 22px;
      }
    }
    &-ctx {
      position: absolute;
      top: 38px;
      left: 44px;
    }
    &-btn {
      width: 141px;
      height: 40px;
      background: #ffffff;
      border-radius: 20px;
      margin-top: 38px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #133c9a;
      line-height: 40px;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>
