import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router'
import store from './store'
import less from 'less'
import getAPI from './utils/js/api'
// import VideoPlayer from 'vue-video-player'
import Video from 'video.js'
import 'video.js/dist/video-js.css'


// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')


Vue.config.productionTip = false

Vue.use(VueAxios, axios);
Vue.use(less)
Vue.use(ElementUI)
// Vue.use(VideoPlayer)

Vue.prototype.$video = Video
Vue.prototype.$axios = axios;
Vue.prototype.$getAPI = getAPI;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
